<template>
  <div class="bg-gray-100 h-full w-full">
    <!-- 轮播图 -->
    <el-carousel :interval="4000" type="card" height="400px" style="display:none;">
      <el-carousel-item v-for="item in scrollData" :key="item">
        <div class="relative">
          <el-image class="w-3/5" :src="item.imageURL" fit="contain"></el-image>
          <div class="absolute left-4 bottom-4 mb-4 w-3/5">
            <div class="text-2xl m-2 text-white">{{item.title}}</div>
            <router-link :to="{ path: '/articleDetail', query: {id: item.articleID }}">
              <el-button type="danger" round>开始阅读</el-button>
            </router-link>
          </div>
        </div>
      </el-carousel-item>
    </el-carousel>
    <!-- 主要内容 -->

    <!-- 左右分隔内容 -->
    <div class="flex justify-between mx-10 mt-10">
      <div class="flex-col w-2/3">
        <div class="ml-3">热度最高</div>
        <div class="infinite-list" style="overflow:auto">
          <el-card
            v-for="item in articleDataHot"
            class="infinite-list-item m-3"
            :key="item.imageURL"
            shadow="always"
          >
            <el-image
              :src="item.imageURL ? item.imageURL : require('../../../static/images/lifestealer.jpg')"
              fit="contain"
            ></el-image>
            <div style="padding: 14px;">
              <div class="text-2xl">{{ item.title }}</div>
              <div class="text-gray-400 text-xs mt-1 mb-1">{{ item.updateTime }}</div>
              <!-- <div>{{ item.detail }}</div> -->

              <router-link :to="{ path: '/articleDetail', query: {id: item.id }}">
                <el-button class="mt-3" type="danger" plain round>开始阅读</el-button>
              </router-link>
            </div>
          </el-card>
        </div>
      </div>
      <div class="flex-col w-1/3">
        <div class="ml-3">最新文章</div>
        <div class="infinite-list" style="overflow:auto">
          <el-card
            v-for="item in articleDataTime"
            class="infinite-list-item m-3"
            :key="item.imageURL"
            shadow="always"
          >
            <el-image
              :src="item.imageURL ? item.imageURL : require('../../../static/images/lifestealer.jpg')"
              fit="contain"
            ></el-image>
            <div style="padding: 14px;">
              <div class="text-2xl">{{ item.title }}</div>
              <div class="text-gray-400 text-xs mt-1 mb-1">{{ item.recordTime }}</div>
              <div>{{ item.detail }}</div>

              <router-link :to="{ path: '/articleDetail', query: {id: item.id }}">
                <el-button class="mt-3" type="danger" plain round>开始阅读</el-button>
              </router-link>
            </div>
          </el-card>
        </div>
      </div>
    </div>
    <!-- <div class="absolute left-1/2 transform -translate-x-1/2 -translate-y-1/2">
          <a href="http://www.beian.miit.gov.cn/">苏ICP备2021028714号-1</a>
          <a href="139.196.160.198">原始IP</a>
    </div>-->

    <div class="flex-col align-middle m-5">
      <div class="flex justify-center align-middle m-3">
        <svg
          t="1626885010499"
          class="icon"
          viewBox="0 0 1024 1024"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          p-id="1607"
          width="25"
          height="25"
        >
          <path
            d="M512.3584 962.4064c-2.816 0-5.6832-0.4096-8.3968-1.1776-182.5792-51.968-308.224-182.272-363.264-376.832C99.84 439.9616 112.2304 312.32 112.7424 306.9952a30.72 30.72 0 0 1 30.1568-27.5968c34.2528-0.4608 60.6208-10.24 78.3872-28.9792 29.7984-31.488 28.6208-79.6672 28.5696-80.1792a30.72 30.72 0 0 1 20.7872-30.3616l227.328-77.4656a30.6688 30.6688 0 0 1 19.968 0.0512c1.3312 0.4608 135.5264 46.8992 238.08 81.2544 13.056 4.4032 21.6576 16.9472 20.9408 30.72-1.8944 35.9936 6.1952 62.976 23.9616 80.1792 29.7472 28.7744 79.4112 24.9344 79.9232 24.9344 16.7936-1.3824 31.744 10.9056 33.3312 27.6992 0.512 5.5808 12.4416 138.3424-33.2288 286.5664a30.73536 30.73536 0 0 1-38.4 20.3264 30.73536 30.73536 0 0 1-20.3264-38.4c30.464-98.816 33.024-191.488 32.0512-235.6224-26.9312-2.7648-65.536-12.0832-95.5904-40.8576-26.7264-25.6-41.216-60.6208-43.1616-104.2944-82.432-27.7504-177.152-60.416-207.6672-70.9632L310.0672 191.3344c-2.9696 25.9072-12.6976 68.0448-44.1344 101.2736-23.8592 25.2416-55.1936 40.6528-93.44 46.08-1.3312 42.7008 0.0512 133.632 27.8528 230.7584 48.9984 171.3152 153.856 282.368 311.808 330.1888 98.4576-31.3344 177.3056-87.7056 234.3936-167.5776a30.68416 30.68416 0 0 1 42.8544-7.1168 30.68416 30.68416 0 0 1 7.1168 42.8544c-66.8672 93.5424-159.488 158.6176-275.3536 193.3824-2.8672 0.8192-5.8368 1.2288-8.8064 1.2288z"
            fill="#474747"
            p-id="1608"
          />
          <path
            d="M413.3376 692.1216c-10.6496 0-21.1968-3.328-30.208-9.8816a51.1488 51.1488 0 0 1-20.48-50.3296l14.4896-84.5312-61.44-59.8528a51.12832 51.12832 0 0 1-13.0048-52.736 51.06176 51.06176 0 0 1 41.5232-35.0208l84.8896-12.3392 37.9392-76.9024c8.7552-17.7152 26.4192-28.672 46.1312-28.672s37.4272 11.008 46.1312 28.672l37.9392 76.9024 84.8896 12.3392c19.5072 2.816 35.4304 16.2816 41.5232 35.0208 6.0928 18.7904 1.1264 38.9632-13.0048 52.736l-61.44 59.8528 14.4896 84.5312c3.328 19.456-4.5056 38.7584-20.48 50.3296-15.9744 11.6224-36.7104 13.1072-54.2208 3.8912l-75.9296-39.936-75.9296 39.936a50.23744 50.23744 0 0 1-23.808 5.9904z m-40.2432-234.496l50.688 49.408c12.1344 11.8272 17.664 28.8768 14.7968 45.568l-11.9808 69.7856 62.6688-32.9216c15.0016-7.8848 32.9216-7.8848 47.872 0l62.6688 32.9728-11.9808-69.7856c-2.8672-16.6912 2.6624-33.7408 14.7968-45.568l50.688-49.408-70.0416-10.1888c-16.7424-2.4576-31.232-12.9536-38.7584-28.16l-31.3344-63.488-31.3344 63.488a51.40992 51.40992 0 0 1-38.7584 28.16l-69.9904 10.1376z"
            fill="#4F95FC"
            p-id="1609"
          />
        </svg>
        <a class="ml-2" href="http://beian.miit.gov.cn/" target="_blank">苏ICP备2021028714号-1</a>
      </div>
      <!-- <div class="flex justify-center align-middle m-3">
            <svg
              t="1626885010499"
              class="icon"
              viewBox="0 0 1024 1024"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              p-id="1607"
              width="25"
              height="25"
            >
              <path
                d="M512.3584 962.4064c-2.816 0-5.6832-0.4096-8.3968-1.1776-182.5792-51.968-308.224-182.272-363.264-376.832C99.84 439.9616 112.2304 312.32 112.7424 306.9952a30.72 30.72 0 0 1 30.1568-27.5968c34.2528-0.4608 60.6208-10.24 78.3872-28.9792 29.7984-31.488 28.6208-79.6672 28.5696-80.1792a30.72 30.72 0 0 1 20.7872-30.3616l227.328-77.4656a30.6688 30.6688 0 0 1 19.968 0.0512c1.3312 0.4608 135.5264 46.8992 238.08 81.2544 13.056 4.4032 21.6576 16.9472 20.9408 30.72-1.8944 35.9936 6.1952 62.976 23.9616 80.1792 29.7472 28.7744 79.4112 24.9344 79.9232 24.9344 16.7936-1.3824 31.744 10.9056 33.3312 27.6992 0.512 5.5808 12.4416 138.3424-33.2288 286.5664a30.73536 30.73536 0 0 1-38.4 20.3264 30.73536 30.73536 0 0 1-20.3264-38.4c30.464-98.816 33.024-191.488 32.0512-235.6224-26.9312-2.7648-65.536-12.0832-95.5904-40.8576-26.7264-25.6-41.216-60.6208-43.1616-104.2944-82.432-27.7504-177.152-60.416-207.6672-70.9632L310.0672 191.3344c-2.9696 25.9072-12.6976 68.0448-44.1344 101.2736-23.8592 25.2416-55.1936 40.6528-93.44 46.08-1.3312 42.7008 0.0512 133.632 27.8528 230.7584 48.9984 171.3152 153.856 282.368 311.808 330.1888 98.4576-31.3344 177.3056-87.7056 234.3936-167.5776a30.68416 30.68416 0 0 1 42.8544-7.1168 30.68416 30.68416 0 0 1 7.1168 42.8544c-66.8672 93.5424-159.488 158.6176-275.3536 193.3824-2.8672 0.8192-5.8368 1.2288-8.8064 1.2288z"
                fill="#474747"
                p-id="1608"
              />
              <path
                d="M413.3376 692.1216c-10.6496 0-21.1968-3.328-30.208-9.8816a51.1488 51.1488 0 0 1-20.48-50.3296l14.4896-84.5312-61.44-59.8528a51.12832 51.12832 0 0 1-13.0048-52.736 51.06176 51.06176 0 0 1 41.5232-35.0208l84.8896-12.3392 37.9392-76.9024c8.7552-17.7152 26.4192-28.672 46.1312-28.672s37.4272 11.008 46.1312 28.672l37.9392 76.9024 84.8896 12.3392c19.5072 2.816 35.4304 16.2816 41.5232 35.0208 6.0928 18.7904 1.1264 38.9632-13.0048 52.736l-61.44 59.8528 14.4896 84.5312c3.328 19.456-4.5056 38.7584-20.48 50.3296-15.9744 11.6224-36.7104 13.1072-54.2208 3.8912l-75.9296-39.936-75.9296 39.936a50.23744 50.23744 0 0 1-23.808 5.9904z m-40.2432-234.496l50.688 49.408c12.1344 11.8272 17.664 28.8768 14.7968 45.568l-11.9808 69.7856 62.6688-32.9216c15.0016-7.8848 32.9216-7.8848 47.872 0l62.6688 32.9728-11.9808-69.7856c-2.8672-16.6912 2.6624-33.7408 14.7968-45.568l50.688-49.408-70.0416-10.1888c-16.7424-2.4576-31.232-12.9536-38.7584-28.16l-31.3344-63.488-31.3344 63.488a51.40992 51.40992 0 0 1-38.7584 28.16l-69.9904 10.1376z"
                fill="#4F95FC"
                p-id="1609"
              />
            </svg>
            <a class="ml-1" href="http://www.beian.gov.cn/portal/recordQuery" target="_blank">全国互联网</a>
      </div>-->

      <!--  -->
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
// import Notes from '@/components/Notes.vue'
// import Notes from './Notes.vue'
// import Prose from './Prose.vue'
// import { reactive, ref } from 'vue'

export default {
  name: 'HomeContent',
  components: {
    // HelloWorld,
    // Notes,
  },

  beforeCreate() {
    console.log('HomeContent')
    // console.log('---beforeCreate---')
  },
  created() {
    // console.log('--created--')
  },
  beforeMount() {
    // console.log('--beforeMount--')
  },
  mounted() {
    // console.log('--mounted--')
    //

    // 获取文章列表 - 热度榜
    this.$http.get(`getUserArticleListHot`).then((res) => {
      console.log(res.data)
      if (res.data.IsOk == 1) {
        this.articleDataHot = res.data.Data

        var arr = []
        for (let i = 0; i < res.data.Data.length; i++) {
          const element = res.data.Data[i]
          arr = arr.concat({
            title: element.title,
            imageURL: element.imageURL ? element.imageURL : this.imgArr[i],
            updateTime: element.element,
            id: i,
            articleID: element.id,
          })
        }
        this.scrollData = arr
      } else {
        this.$message.error('获取失败!')
      }
    })

    // 获取文章列表 - 最新文章
    this.$http.get(`getUserArticleListTime`).then((res) => {
      console.log(res.data)
      if (res.data.IsOk == 1) {
        this.articleDataTime = res.data.Data
      } else {
        this.$message.error('获取失败!')
      }
    })
  },
  data() {
    return {
      isActive: false,

      activeIndex: '1',
      imgArr: [
        require('../../../static/images/lifestealer.jpg'),
        require('../../../static/images/tidehunter.jpg'),
        require('../../../static/images/dota2.jpg'),
      ],
      articleDataHot: [],
      articleDataTime: [],
      scrollData: {
        title: '',
        image: '',
        updateTime: '',
        id: 0,
        articleID: '',
      },
      fits: ['fill', 'contain', 'cover', 'none', 'scale-down'],
      // url: 'https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg',
      // url: require('../../static/images/dota2.jpg'),
    }
  },
  methods: {
    // // 手动改变图片
    // imgChange(e) {
    //   console.log(e)
    //   // 手动取消聚焦
    //   // e.target.blur()
    // },
  },
}
</script>

<style scoped>
.el-button--text {
  margin-right: 15px;
}
.el-select {
  width: 300px;
}
.el-input {
  width: 300px;
}
.dialog-footer button:first-child {
  margin-right: 10px;
}
</style>
