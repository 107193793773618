import { createRouter, createWebHashHistory } from 'vue-router'
import Home from '../views/Home.vue'
import HomeContent from '../components/homeContent/HomeContent.vue'
import Article from '../components/article/Article.vue'
import ArticleList from '../components/articleList/ArticleList.vue'
import ArticleDetail from '../components/articleList/ArticleDetail.vue'
import MessageWall from '../components/messageWall/MessageWall.vue'

const routes = [{
    path: '/',
    name: 'Home',
    component: Home,
    redirect: '/homeContent',
    children: [{
        path: '/homeContent',
        component: HomeContent,
    }, {

        path: '/article',
        component: Article,
    }, {

        path: '/messageWall',
        component: MessageWall,
    }, {

        component: ArticleList,
        path: '/articleList',
    }, {
        path: '/articleDetail',
        component: ArticleDetail,
    },]
},
    // {
    //     path: '/homeContent',
    //     name: 'HomeContent',
    //     component: HomeContent
    // },
]

const router = createRouter({
    history: createWebHashHistory(),
    routes
})

export default router