<template>
  <el-breadcrumb class="mt-3 ml-3" separator="/">
    <el-breadcrumb-item :to="{ path: '/articleList' }">文章列表</el-breadcrumb-item>
    <el-breadcrumb-item>{{articleTitle}}</el-breadcrumb-item>
  </el-breadcrumb>
  <div class="flex items-center justify-between mt-3">
    <div class="flex items-center w-3/5">
      <div class="text-xl font-bold w-28 ml-3">文章标题:</div>
      <el-input
        class="ml-2"
        placeholder="请输入文章标题..."
        v-model="articleTitle"
        maxlength="50"
        show-word-limit
      ></el-input>
    </div>
    <div class="flex items-center">
      <div class="flex items-center mr-3">
        <el-radio class="mr-3" v-model="articleOvert" :label="0">私有</el-radio>
        <el-radio v-model="articleOvert" :label="1">公开</el-radio>
      </div>

      <el-button class="mr-3" type="info" plain @click="saveDraft">保存草稿</el-button>

      <div v-if="editStatus == true">
        <el-button type="danger" plain @click="editArticles">修改文章</el-button>
      </div>
      <div v-else>
        <el-button type="danger" plain @click="publishArticles">发布文章</el-button>
      </div>
    </div>
  </div>
  <div>
    <div style="border: 1px solid #ccc; margin-top: 10px;">
      <Toolbar
        :editor="editorRef"
        :defaultConfig="toolbarConfig"
        :mode="mode"
        style="border-bottom: 1px solid #ccc"
      />
      <Editor
        :defaultConfig="editorConfig"
        :mode="mode"
        v-model="valueHtml"
        style="height: 800px; overflow-y: hidden;"
        @onCreated="handleCreated"
      />

      <!-- 
        @onChange="handleChange" 编辑器内容、选区变化时的回调函数。
        @onDestroyed="handleDestroyed" 编辑器销毁时的回调函数。调用 editor.destroy() 即可销毁编辑器，详见 API 。
        @onFocus="handleFocus"
        @onBlur="handleBlur"
        @customAlert="customAlert"
      @customPaste="customPaste"-->
    </div>
  </div>
</template>

<script>
import '@wangeditor/editor/dist/css/style.css'
import { onBeforeUnmount, ref, shallowRef, onMounted } from 'vue'
import { Editor, Toolbar } from '@wangeditor/editor-for-vue'

// import { SlateTransforms } from '@wangeditor/editor'
// import { IEditorConfig } from '@wangeditor/editor'

export default {
  components: { Editor, Toolbar },
  data() {
    return {
      articleTitle: '',
      articleOvert: 0,
      clientID: localStorage.getItem('LiuguoBlog_clientID'),
      articleID: '',
    }
  },
  setup() {
    // const editorId = 'editor' //【注意】编辑器 id ，要全局唯一
    // 编辑器实例，必须用 shallowRef，重要！
    const editorRef = shallowRef()
    // 内容 HTML
    const valueHtml = ref('')

    const toolbarConfig = {
      //此处不需要的工具栏选项
      excludeKeys: [
        'fullScreen',
        'group-video', // 排除菜单组，写菜单组 key 的值即可'
      ],
    }
    // 转换图片链接
    function customParseImageSrc(src) {
      console.log('2', src)
      if (src.indexOf('http') !== 0) {
        return `http://${src}`
      }
      return src
    }
    // var firstImage = false;
    const editorConfig = {
      placeholder: '请输入文章内容...',
      // MENU_CONF 重点
      MENU_CONF: {
        uploadImage: {
          // server: 'http://106.12.198.214:3000/api/upload-img', // 上传图片地址
          // fieldName: 'fieldName',
          server: 'http://139.196.160.198:8888/wangeditorUploadImage', // 上传图片地址
          fieldName: 'fileName',
          // 单个文件的最大体积限制，默认为 2M
          maxFileSize: 5 * 1024 * 1024, // 1M
          // 最多可上传几个文件，默认为 100
          maxNumberOfFiles: 10,
          // 选择文件时的类型限制，默认为 ['image/*'] 。如不想限制，则设置为 []
          allowedFileTypes: ['image/*'],
          // // 自定义上传参数，例如传递验证的 token 等。参数会被添加到 formData 中，一起上传到服务端。
          meta: {
            fileName: 'fileName',
          },
          // // 将 meta 拼接到 url 参数中，默认 false
          // metaWithUrl: false,
          // 自定义增加 http  header
          // headers: {
          //   'Access-Control-Allow-Origin': '*',
          //   'Access-Control-Allow-Headers': 'Content-Type',
          //   'Access-Control-Allow-Methods': 'PUT,POST,GET,DELETE,OPTIONS',
          //   'X-Powered-By': ' 3.2.1',
          //   'Content-Type': 'application/json;charset=utf-8',
          // },
          // 跨域是否传递 cookie ，默认为 false
          // withCredentials: true,

          // // 自定义上传
          // async customUpload(file, insertFn) {
          //   // file 即选中的文件
          //   // 自己实现上传，并得到图片 url alt href
          //   // 最后插入图片
          //   // insertFn(url, alt, href)
          //   console.log(file, insertFn)
          // },
          // // 自定义插入图片
          // customInsert(res, insertFn) {
          //   // res 即服务端的返回结果
          //   console.log(res, insertFn)

          //   // 从 res 中找到 url alt href ，然后插图图片
          //   // insertFn(url, alt, href)
          // },
          // // 自定义选择图片
          // customBrowseAndUpload(insertFn) {
          //   console.log(insertFn)
          //   // 自己选择文件
          //   // 自己上传文件，并得到图片 url alt href
          //   // 最后插入图片
          //   // insertFn(url, alt, href)
          // },
          // 上传之前触发
          onBeforeUpload(file) {
            // file 选中的文件，格式如 { key: file }
            return file

            // 可以 return
            // 1. return file 或者 new 一个 file ，接下来将上传
            // 2. return false ，不上传这个 file
          },
          // 上传进度的回调函数
          onProgress(progress) {
            // progress 是 0-100 的数字
            console.log('progress', progress)
          },
          // 单个文件上传成功之后
          onSuccess(file, res) {
            console.log(`${file.name} 上传成功`, res)
          },
          // 单个文件上传失败
          onFailed(file, res) {
            console.log(`${file.name} 上传失败`, res)
          },
          // 上传错误，或者触发 timeout 超时
          onError(file, err, res) {
            console.log(`${file.name} 上传出错`, err, res)
          },
        },
        insertImage: {
          parseImageSrc: customParseImageSrc, // 重要，否则不显示图片

          // onInsertedImage(imageNode) {
          //   if (imageNode == null) return
          //   const { src, alt, url, href } = imageNode
          //   console.log('inserted image', src, alt, url, href)
          // },
        },
      },
    }

    // console.log('editorConfig', editorConfig)

    // 模拟 ajax 异步获取内容
    onMounted(() => {
      // setTimeout(() => {
      //   valueHtml.value = '<p>模拟 Ajax 异步设置内容</p>'
      // }, 1500)
    })

    // // 编辑器回调函数
    const handleCreated = (editor) => {
      // console.log('created', editor)
      editorRef.value = editor // 记录 editor 实例，重要！
      // console.log('getAllMenuKeys', editor.getAllMenuKeys())
    }
    // 组件销毁时，也及时销毁编辑器，重要！
    onBeforeUnmount(() => {
      const editor = editorRef.value
      if (editor == null) return

      editor.destroy()
    })

    return {
      editorRef,
      mode: 'default',
      valueHtml,
      toolbarConfig,
      editorConfig,
      handleCreated,
      editStatus: false,
    }
  },
  mounted() {
    this.articleID = this.$route.query.id
    // type == 1 是修改
    var edit = this.$route.query.edit
    // 修改文章
    if (edit) {
      // 更改编辑按钮
      this.editStatus = true
      // 填充内容
      // 获取文章详情
      this.$http.get(`getUserArticle?id=${this.articleID}`).then((res) => {
        console.log(res.data)
        if (res.data.IsOk == 1) {
          this.articleTitle = res.data.Data.title
          this.valueHtml = res.data.Data.content
          this.articleOvert = res.data.Data.overt
        } else {
          this.$message.error('获取失败!')
        }
      })
      console.log(this.articleID)
      console.log(edit)
    }

    // setTimeout(() => {
    //   this.valueHtml.value = '<p>模拟 Ajax 异步设置内容</p>'
    // }, 1500)
    // this.valueHtml.value = '<p>213123</p>'
    // SlateTransforms.removeNodes(this.editorRef.value, { at: [0] }) // 删除第一行
  },
  methods: {
    // 保存草稿
    saveDraft() {
      // console.log(this.articleTitle)
      // console.log(this.valueHtml)
      // this.$message.error('建设中!')
    },
    // 创建文章
    publishArticles() {
      var clientID = localStorage.getItem('LiuguoBlog_clientID')
      var params = {
        clientID: clientID,
        title: this.articleTitle,
        content: this.valueHtml,
        overt: this.articleOvert,
        imageURL: '',
      }

      var imageArr = this.editorRef.getElemsByType('image')
      // console.log(imageArr)
      if (imageArr.length > 0) {
        // console.log(imageArr[0])
        params.imageURL = imageArr[0].src
      }
      // console.log(params)

      if (!clientID) {
        this.$message.error('未查到用户ID!')
      } else if (!this.articleTitle) {
        this.$message.error('请输入标题!')
      } else if (this.valueHtml == '<p><br></p>') {
        this.$message.error('请输入内容!')
      } else {
        this.$http.post('postArticle', params).then((res) => {
          //请求的数据存储在res.data 中
          // console.log(res)
          if (res.data.IsOk == 1) {
            this.$message({
              message: '上传成功!',
              type: 'success',
            })
          } else {
            this.$message.error('上传失败!')
          }
        })
      }
    },

    // 修改文章
    editArticles() {
      var params = {
        id: this.articleID,
        title: this.articleTitle,
        content: this.valueHtml,
        overt: this.articleOvert,
        imageURL: '',
      }

      var imageArr = this.editorRef.getElemsByType('image')
      // console.log(imageArr)
      if (imageArr.length > 0) {
        // console.log(imageArr[0])
        params.imageURL = imageArr[0].src
      }
      console.log(params)

      if (!this.clientID) {
        this.$message.error('未查到用户ID!')
      } else if (!this.articleTitle) {
        this.$message.error('请输入标题!')
      } else if (this.valueHtml == '<p><br></p>') {
        this.$message.error('请输入内容!')
      } else {
        this.$http.post('editArticle', params).then((res) => {
          //请求的数据存储在res.data 中
          // console.log(res)
          if (res.data.IsOk == 1) {
            this.$message({
              message: res.data.Msg,
              type: 'success',
            })
          } else {
            this.$message.error('修改失败!')
          }
        })
      }
    },
  },
}
</script>