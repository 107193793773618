<template>
  <!-- 导航栏 -->
  <div class="bg-gray-100 h-full w-full">
    <div class="relative h-24 jp-2 bg-gradient-to-r from-green-300 via-blue-200 to-green-200 ...">
      <router-link :to="{ path: '/'}">
        <div class="absolute m-auto top-1/4 left-5 text-white text-5xl font-serif">流过的博客</div>
      </router-link>
      <div class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
        <el-menu
          :default-active="activeIndex"
          mode="horizontal"
          background-color="#00CED1"
          text-color="#ffffff"
          active-text-color="#7FFFD4"
        >
          <el-menu-item class="text-xl" index="1">
            <router-link :to="{ path: '/homeContent' }">首页</router-link>
          </el-menu-item>
          <el-menu-item class="text-xl" index="2">
            <router-link :to="{ path: '/articleList' }">笔记</router-link>
          </el-menu-item>

          <!-- <router-link to="/Notes">笔记</router-link> -->
          <el-menu-item class="text-xl" index="3">
            <router-link :to="{ path: '/messageWall' }">留言</router-link>
          </el-menu-item>
        </el-menu>
      </div>

      <div v-if="loginStatus === 'login'">
        <el-dropdown class="absolute top-1/4 right-5" @command="userManageClick">
          <el-button class="w-10 el-icon-user" type="success" circle></el-button>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item command="accountManagement">账户管理</el-dropdown-item>
              <el-dropdown-item command="logout" divided>注销</el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </div>
      <div v-else>
        <el-button
          class="absolute top-1/4 right-5 w-10 el-icon-user"
          type="success"
          @click="loginDialog = true"
          circle
        ></el-button>
      </div>

      <!-- 登录弹窗 -->
      <el-dialog v-model="loginDialog" title="登录">
        <el-form :model="loginForm" status-icon>
          <el-form-item label="账号" :label-width="formLabelWidth">
            <el-input v-model="loginForm.phone" autocomplete="on" placeholder="请输入手机号" />
          </el-form-item>
          <el-form-item label="密码" :label-width="formLabelWidth" prop="pass">
            <el-input
              v-model="loginForm.password"
              autocomplete="off"
              type="password"
              placeholder="请输入密码"
              show-password
            />
          </el-form-item>
        </el-form>
        <template #footer>
          <span class="dialog-footer justify-between align-middle">
            <el-button class="absolute left-5" type="primary" @click="registerBtn">注册</el-button>
            <div>
              <el-button @click="loginDialog = false">取消</el-button>
              <el-button type="success" @click="loginBtn">登录</el-button>
            </div>
          </span>
        </template>
      </el-dialog>

      <!-- 注册弹窗 -->
      <el-dialog v-model="registerDialog" title="注册">
        <el-form :model="registerForm" status-icon>
          <el-form-item label="昵称" :label-width="formLabelWidth">
            <el-input v-model="registerForm.name" placeholder="请输入昵称" />
          </el-form-item>
          <el-form-item label="性别" :label-width="formLabelWidth">
            <el-radio v-model="registerForm.gender" label="男" size="large">男</el-radio>
            <el-radio v-model="registerForm.gender" label="女" size="large">女</el-radio>
          </el-form-item>
          <el-form-item label="手机号" :label-width="formLabelWidth">
            <el-input v-model="registerForm.phone" placeholder="请输入手机号" />
          </el-form-item>
          <el-form-item label="邮箱" :label-width="formLabelWidth">
            <el-input v-model="registerForm.email" placeholder="可不填" />
          </el-form-item>
          <el-form-item label="输入密码" :label-width="formLabelWidth">
            <el-input
              v-model="registerForm.password"
              type="password"
              autocomplete="off"
              placeholder="请输入密码"
              show-password
            />
          </el-form-item>
          <el-form-item label="确认密码" :label-width="formLabelWidth">
            <el-input
              v-model="registerForm.checkPass"
              type="password"
              autocomplete="off"
              placeholder="请确认密码"
              show-password
            />
          </el-form-item>
        </el-form>
        <template #footer>
          <span class="dialog-footer">
            <el-button @click="registerDialog = false">取消</el-button>
            <el-button type="primary" @click="registerFormCommit">注册</el-button>
          </span>
        </template>
      </el-dialog>
    </div>
    <router-view></router-view>
  </div>
</template>

<script>
// @ is an alias to /src
// import HomeContent from '@/components/homeContent/HomeContent.vue'
// import Notes from './Notes.vue'
// import Prose from './Prose.vue'
import { ref } from 'vue'

export default {
  name: 'Home',
  components: {
    // HomeContent,
    // Notes,
  },

  beforeCreate() {
    // console.log('---beforeCreate---')
  },
  created() {
    // console.log('--created--')
    // this.$router.push('/homeContent')
  },
  beforeMount() {
    // console.log('--beforeMount--')
  },
  mounted() {
    // console.log('--mounted--')
    var clientID = localStorage.getItem('LiuguoBlog_clientID')
    if (clientID) {
      // 设置右上角按钮状态为已登录
      this.loginStatus = 'login'
      // 获取个人信息
      this.$http.get(`getUserInfo?id=${clientID}`).then((res) => {
        console.log(res.data)
      })

      // this.$http.post('login', { user: 123, pwd: 123 }).then((res) => {
      //   //请求的数据存储在res.data 中
      // })
    } else {
      // 设置右上角按钮状态为未登录
      this.loginStatus = 'logout'

      // // 多并发请求
      // var r1 = this.$http.get(
      //   `getUserInfo?id=673956ba-75f7-42f5-b0d1-bdedd8a67f8e`
      // )
      // var r2 = this.$http.get(`userList`)
      // // var r2 = this.$http.post('login', { user: 123, pwd: 123 })

      // this.$http.all([r1, r2]).then(
      //   this.$http.spread(function (res1, res2) {
      //     console.log('完成')
      //     console.log('res1:', res1)
      //     console.log('res2:', res2)
      //   })
      // )
    }
    // 图片获取地址
    // http://www.liuguo.top/upload/  + 图片名
  },
  data() {
    return {
      isActive: false,

      activeIndex: '1',

      loginDialog: ref(false), //登录弹窗
      registerDialog: ref(false), //注册弹窗
      formLabelWidth: '80px', // 弹窗输入框前面文字框宽度
      loginStatus: 'login', // 登录按钮切换
      // 登录表单数据
      loginForm: {
        phone: '',
        password: '',
      },
      // 注册表单数据
      registerForm: {
        name: '',
        phone: '',
        password: '',
        checkPass: '',
        gender: '男',
        email: '',
      },
    }
  },
  methods: {
    // 切换注册界面
    registerBtn() {
      this.loginDialog = false
      this.registerDialog = true
    },
    userManageClick(command) {
      if (command === 'accountManagement') {
        // 账户管理
        console.log('a')
      } else if (command === 'logout') {
        // 注销
        console.log('b')
        // 清除clientID
        localStorage.removeItem('LiuguoBlog_clientID')
        // 刷新界面
        location.reload()
      }
    },

    // 登录
    async loginBtn() {
      console.log(this.loginForm)
      const { data: mess } = await this.$http.post(`login`, {
        phone: this.loginForm.phone,
        password: this.loginForm.password,
      })
      if (mess.IsOk == 1) {
        // 存储ID
        localStorage.setItem('LiuguoBlog_clientID', mess.Data.id)

        // 消息提示
        this.$message({
          message: mess.Msg,
          type: 'success',
        })
        // 关闭弹窗
        this.loginDialog = false

        // 更新登录按钮

        // 刷新界面
        location.reload()
      } else {
        this.$message.error(mess.Msg)
      }
    },
    // 注册
    async registerFormCommit() {
      console.log(this.registerForm)
      var params = {
        name: this.registerForm.name,
        gender: this.registerForm.gender,
        phone: this.registerForm.phone,
        password: this.registerForm.password,
        email: this.registerForm.email,
        level: '1',
      }
      if (!this.registerForm.name) {
        this.$message.error('请输入用户名!')
      } else if (!this.registerForm.phone) {
        this.$message.error('请输入手机号!')
      } else if (!this.registerForm.password) {
        this.$message.error('请输入密码!')
      } else if (this.registerForm.password != this.registerForm.checkPass) {
        this.$message.error('两次密码输入不一致!')
      } else {
        // console.log(params);
        this.$http.post('addUser', params).then((res) => {
          //请求的数据存储在res.data 中
          console.log(res)
          if (res.data.IsOk == 1) {
            this.$message({
              message: '注册成功!',
              type: 'success',
            })
            // 存储ID
            localStorage.setItem('LiuguoBlog_clientID', res.data.Data)
            // 刷新界面
            location.reload()
            // // 关闭弹窗
            // this.registerDialog = false
          } else {
            this.$message.error('注册失败!')
          }
        })
      }
    },

    // // 手动改变图片
    // imgChange(e) {
    //   console.log(e)
    //   // 手动取消聚焦
    //   // e.target.blur()
    // },
  },

  // beforeUpdate() {
  //   console.log('--beforeUpdate--')
  // },
  // updated() {
  //   console.log('--updated--')
  // },
  // beforeUnmount() {
  //   console.log('--beforeUnmount--')
  // },
  // unmounted() {
  //   console.log('--unmounted--')
  // },
}
</script>

<style scoped>
.el-button--text {
  margin-right: 15px;
}
.el-select {
  width: 300px;
}
.el-input {
  width: 300px;
}
.dialog-footer button:first-child {
  margin-right: 10px;
}
</style>
