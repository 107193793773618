<template>
  <div class="flex justify-end">
    <router-link :to="{ path: '/article'}">
      <el-button class="mt-3 mr-3" type="success" plain>写文章</el-button>
    </router-link>
  </div>

  <div class="flex flex-wrap" style="overflow:auto">
    <el-card v-for="item in ArticleData" class="m-3 w-80" :key="item.imageURL" shadow="always">
      <el-image
        class="w-36 h-36"
        :src="item.imageURL ? item.imageURL : require('../../../static/images/lifestealer.jpg')"
        fit="contain"
      ></el-image>
      <div style="padding: 14px;">
        <div class="text-2xl">{{ item.title }}</div>
        <div class="text-gray-400 text-xs mt-1 mb-1">{{ item.updateTime }}</div>
        <div>{{ item.detail }}</div>
        <div class="flex items-center justify-between">
          <router-link :to="{ path: '/articleDetail', query: {id: item.id }}">
            <el-button class="mt-3" type="success" round>查看</el-button>
          </router-link>
          <router-link :to="{ path: '/article', query: {id: item.id, edit: true }}">
            <el-button class="mt-3" type="info" round>修改</el-button>
          </router-link>

          <!-- <el-popconfirm title="你确定要删除?">
            <template #reference>
              <el-button class="mt-3" type="danger" round @click="articleDelete(item.id)">删除</el-button>
            </template>
          </el-popconfirm>-->

          <el-popconfirm
            confirm-button-text="删除"
            cancel-button-text="取消"
            icon-color="red"
            title="你确定要删除?"
            @confirm="articleDelete(item.id)"
          >
            <template #reference>
              <el-button class="mt-3" type="danger" round>删除</el-button>
            </template>
          </el-popconfirm>
        </div>
      </div>
    </el-card>
  </div>
  <!-- <router-view></router-view> -->
</template>


<script>
// @ is an alias to /src
// import HomeContent from '@/components/homeContent/HomeContent.vue'
// import ArticleDetail from '@/components/ArticleDetail/ArticleDetail.vue'
// import Prose from './Prose.vue'
// import {ref } from 'vue'

export default {
  name: 'ArticleList',
  components: {
    // ArticleDetail,
    // Notes,
  },
  data() {
    return {
      ArticleData: [],
    }
  },

  beforeCreate() {
    // console.log('---beforeCreate---')
  },
  created() {
    // console.log('--created--')
    // this.$router.push('/homeContent')
  },
  beforeMount() {
    // console.log('--beforeMount--')
  },
  mounted() {
    // console.log('--mounted--')
    var clientID = localStorage.getItem('LiuguoBlog_clientID')
    if (clientID) {
      // 获取文章列表
      this.$http.get(`getUserArticleList?clientID=${clientID}`).then((res) => {
        console.log(res.data)
        if (res.data.IsOk == 1) {
          // this.$message({
          //   message: '获取成功!',
          //   type: 'success',
          // })
          this.ArticleData = res.data.Data
        } else {
          this.$message.error('获取失败!')
        }
      })
    } else {
      this.$message.error('未登录!')
    }
  },
  methods: {
    // 删除文章
    articleDelete(id) {
      var clientID = localStorage.getItem('LiuguoBlog_clientID')
      console.log(id)
      // 删除文章
      this.$http
        .get(
          `http://139.196.160.198:8888/deleteArticle?id=${id}&clientID=${clientID}`
        )
        .then((res) => {
          console.log(res.data)
          if (res.data.IsOk == 1) {
            this.$message({
              message: '删除成功!',
              type: 'success',
            })
            this.ArticleData = res.data.Data
          } else {
            this.$message.error('删除失败!')
          }
        })
    },
  },
}
</script>
