import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import installElementPlus from './plugins/element'
import './assets/tailwind.css'

import axios from 'axios'//引入axios
import VueAxios from 'vue-axios'
import message from 'element-plus'

const app = createApp(App)






axios.defaults.baseURL = 'http://139.196.160.198:8888/'






app.use(VueAxios, axios, message)

installElementPlus(app)
app.use(store).use(router).mount('#app')
