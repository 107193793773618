<template>
   <!-- 路由占位符 -->
  <router-view></router-view>
</template>

<script>
export default {
  name: 'App',
}
</script>
 
