<template>
  <el-breadcrumb class="mt-3 ml-3" separator="/">
    <el-breadcrumb-item :to="{ path: '/articleList' }">文章列表</el-breadcrumb-item>
    <el-breadcrumb-item>{{articleTitle}}</el-breadcrumb-item>
    <!-- 点击禁止编辑  此处使用定时器延迟自动点击 -->
    <button @click="disable"></button>
  </el-breadcrumb>

  <div class="text-xl font-bold w-full ml-3 mt-3 text-center">{{articleTitle}}</div>
  <div>
    <div style="border: 1px solid #ccc; margin-top: 10px;">
      <Toolbar
        :editorId="editorId"
        :editor="editorRef"
        :defaultConfig="toolbarConfig"
        :mode="mode"
        style="border-bottom: 1px solid #ccc"
      />
      <Editor
        :editorId="editorId"
        :defaultConfig="editorConfig"
        :mode="mode"
        v-model="valueHtml"
        style="height: 800px; overflow-y: hidden;"
        @onCreated="handleCreated"
      />
    </div>
    <!-- <div style="margin-top: 10px">
      <textarea v-model="valueHtml" readonly style="width: 100%; height: 200px; outline: none"></textarea>
    </div>-->
  </div>
</template>

<script>
import '@wangeditor/editor/dist/css/style.css'
import { onBeforeUnmount, ref, shallowRef, onMounted } from 'vue'
import { Editor, Toolbar } from '@wangeditor/editor-for-vue'

// import { SlateTransforms } from '@wangeditor/editor'
// import { IEditorConfig } from '@wangeditor/editor'

export default {
  components: { Editor, Toolbar },
  data() {
    return {
      articleTitle: '',
    }
  },
  setup() {
    const editorId = 'editor' //【注意】编辑器 id ，要全局唯一
    // 编辑器实例，必须用 shallowRef，重要！
    const editorRef = shallowRef()
    // 内容 HTML
    const valueHtml = ref('')

    const toolbarConfig = {
      //此处不需要的工具栏选项
      toolbarKeys: [],
    }
    const editorConfig = {
      // placeholder: '请输入文章内容...',
      // MENU_CONF 重点
      // MENU_CONF: {
      //   readOnly: true,
      // },
      // readOnly: true,
    }

    // console.log('editorConfig', editorConfig)

    // 模拟 ajax 异步获取内容
    onMounted(() => {
      console.log('1234')
      // setTimeout(() => {
      //   valueHtml.value = '<p>模拟 Ajax 异步设置内容</p>'
      // }, 1500)
    })

    // // 编辑器回调函数
    const handleCreated = (editor) => {
      console.log('created', editor)
      editorRef.value = editor // 记录 editor 实例，重要！
      // console.log('getAllMenuKeys', editor.getAllMenuKeys())
      setTimeout(() => {
        disable()
      }, 500)
    }

    const disable = () => {
      const editor = editorRef.value
      if (editor == null) return
      editor.disable()
    }

    // 组件销毁时，也及时销毁编辑器，重要！
    onBeforeUnmount(() => {
      const editor = editorRef.value
      if (editor == null) return
      editor.destroy()
    })

    return {
      editorId,
      editorRef,
      mode: 'default',
      valueHtml,
      toolbarConfig,
      editorConfig,
      handleCreated,
      disable,
    }
  },
  mounted() {
    console.log('123')
    var articleID = this.$route.query.id
    if (articleID) {
      // 获取文章详情
      this.$http.get(`getUserArticle?id=${articleID}`).then((res) => {
        console.log(res.data)
        if (res.data.IsOk == 1) {
          this.articleTitle = res.data.Data.title
          this.valueHtml = res.data.Data.content
        } else {
          this.$message.error('获取失败!')
        }
      })
    } else {
      this.$message.error('返回上级重试!')
    }
  },
  methods: {},
}
</script>